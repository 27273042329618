.root {
  height: 100vh;
}
.background {
  background-color: #282c34;
  min-height: 100vh;
  position: relative;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: chocolate;
}
.user-list {
  margin: 0 0 0 25px;
  font-size: 24px;
  padding: 40px;
  &-item {
    margin-bottom: 10px;
  }
}
.photo {
  width: 100%;
}
.logotype {
  display: flex;
  justify-content: center;
  img {
    width: calc(100% - 20px);
  }
}
.tillbaka {
  position: absolute;
  bottom: 50px;
  right: 20px;
}
a {
  color: white;
  font-size: 24px;
}
ol {
  color: white;
}

@media only screen and (min-width: 600px) {
  .profile {
    width: 300px;
    margin: 0% auto;
    padding-top: 10%;
  }
}
